
import {Actions} from "@/store/enums/StoreEnums";
import {computed, defineComponent, nextTick, onBeforeMount, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import VideoPlayer from "@/views/apps/videojs/Player.vue";
import CardMachineLesson from "@/components/course/lesson/CardMachineLesson.vue";
import Tag from "@/components/common/Tag.vue";
import router from "@/router";
import type {Challenge} from "@/types/ChallengeType";
import ApiService from "@/core/services/ApiService";
import CardRecommendMachine from "@/components/course/lesson/CardRecommendMachine.vue";
import type {MachineType} from "@/types/MachineType";
import CardSubmitComment from "@/components/course/lesson/CardSubmitComment.vue";
import HacktivityWidget from "@/components/widgets/feeds/Widget6.vue";
import {UserType} from "@/types/UserType";
import CardCommentFake from "@/components/common/CardCommentFake.vue";
import {Mutations} from "@/store/enums/StoreEnums";
import {LessonType} from "@/types/LessonType";
import { initializeComponents } from '@/core/plugins/keenthemes';
import { hasFeature } from '@/core/helpers/feature';

export default defineComponent({
name: "lesson-show-on-learning-path",
props: {
  id: {
    type: String,
    required: true
  },
  lesson_id: {
    type: String,
    required: false
  }
},
components: {
  CardCommentFake,
  HacktivityWidget,
  CardSubmitComment,
  CardRecommendMachine,
  VideoPlayer, Tag, CardMachineLesson
},
setup( props ) {
  const store = useStore();
  const srcVideo = ref("");
  const titleVideo = ref("");
  const descVideo = ref("");
  const tags = ref({});
  const lessonId = ref(0)
  const isStarted = ref(false);
  const showSpinner = ref(false);
  const links = ref<string[]>([]);
  const attachments = ref([]);

  const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
  const user = computed<UserType>(() => store.getters.currentUser);

  const getIsStartedClass = (classIsStarted: boolean) => {
    return classIsStarted ? 'badge-light-success  border-success' :  'badge-light-warning border-warning';
  };

  

 
  store.dispatch(Actions.GET_PATHWAY, props.id);
  store.dispatch(Actions.GET_LESSON, props.lesson_id);

  const pathway = computed(() => {
    return store.getters.getCurrentPathway;
  });

  const lesson  = computed(() => {
    return store.getters.getLessonData;
  });

  const nextItem = (lesson) => {
    // get next item from pathway_resources with 'App\Models\Lesson' type and id of lesson.value.id
    var index;
    console.log(pathway.value.pathway_resources);
    console.log(lesson);
    pathway.value.pathway_resources.forEach((item, i) => {
      if(item.resource_id == lesson.id && item.resource_type == 'App\\Models\\Lesson'){
        index = i;
      }
    });

    console.log("index", index);

    // check if index is the last item on the list
    if (index === pathway.value.pathway_resources.length - 1) {
    console.log("É o último item do array");
    router.push({name: 'learning-paths-show', params: {id: props.id}});
    return null; // ou o que preferir retornar para o último item
    } else {
      const nextIndex = index + 1;
      const next = pathway.value.pathway_resources[nextIndex];
      console.log("Próximo item:", next);

      if(next.resource_type == 'App\\Models\\Lesson'){
        router.push({name: 'learning-paths-lesson-show', params: {id: props.id, lesson_id: next.resource_id}});
        store.dispatch(Actions.GET_LESSON, next.resource_id);
      }else if(next.resource_type == 'App\\Models\\Machine'){
        router.push({name: 'learning-paths-machine-show', params: {id: props.id, machine_id: next.resource_id}});
      }else if(next.resource_type == 'App\\Models\\Challenge'){
        router.push({name: 'learning-paths-challenge-show', params: {id: props.id, challenge_id: next.resource_id}});
      }
    }
  };

  const toggleWatchedLesson = async () => {
   
    isStarted.value = !isStarted.value;
    // scroll to top
    //window.scrollTo(0, 0);
    try {
      await store.dispatch(Actions.UPDATE_WATCHED_CHECK, {id: lesson.value.id});
      await store.dispatch(Actions.GET_LESSON, lesson.value.id);  
      await store.dispatch(Actions.GET_PATHWAY, props.id);
    } catch (error) {
      console.error('Error checking the lesson:', error);
    }
  };

  const linksToArray= (link: string | null) =>{
    if(link === null) {
      return [];
    }else{
      return link.split(';');
    }
  }
  return {
    user,
    lesson,
    pathway,
    router,
    titleVideo,
    descVideo,
    Tag,
    tags,
    isStarted,
    toggleWatchedLesson,
    getIsStartedClass,
    showSpinner,
    links,
    currentLanguage,
    attachments,
    nextItem,
    hasFeature
  };
}
});
