
import { computed, defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";
import moment from "moment";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {},
  data() {
    return {
      a:1
    };
    }
  ,
  setup() {

    console.log("LOADING MACHINES LIST");
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const current_page = ref(0)
    const loading = ref(false);
    const filter = ref({
        dificulty: "",
        status: "",
        search: "",
        show_filter: false
    });
    const show_filter = ref(false);

    // parse the query string to get the page number and search query
    const query : any = router.currentRoute.value.query;
    const page : number = query.page ? parseInt(query?.page) : 1;
    const search : string = query.search ? query.search : "";
    const dificulty : string = query.dificulty ? query.dificulty : "";
    const status : string = query.status ? query.status : "";
    // read value of data variable


    onMounted(() => {
      setCurrentPageTitle("Machines List");
    });

    if(page !== 1 || search !== "" || dificulty !== "" || status !== ""){

      filter.value.dificulty = dificulty;
      filter.value.status = status;
      filter.value.search = search;
      filter.value.show_filter = true;
      show_filter.value = true;

      store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default", search: search, page: page, dificulty: dificulty, status: status });
    }else{
      store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default"});
    }

    const machines = computed(() => {
      return store.getters.getAllMachines;
    });


    const currentMetadata = computed(() => {
      return store.getters.getMachineMetadata;
    });

    

    return {
      moment,
      router,
      machines,
      currentMetadata,
      store,
      show_filter,
      currentLanguage,
      filter,
      loading,
      current_page
    };
  },
  methods: {
    async goTo(url) {
      // push page to the url - check if already have a search query and concatenate the page number

      if(router.currentRoute.value.query.search || router.currentRoute.value.query.dificulty || router.currentRoute.value.query.status){
        window.history.pushState({}, "", "/laboratory/competition-machines?page=" + url.split("page=")[1] + "&search=" + router.currentRoute.value.query.search + "&dificulty=" + this.filter.dificulty + "&status=" + this.filter.status);
      }else{
        window.history.pushState({}, "", "/laboratory/competition-machines?page=" + url.split("page=")[1]);
      }

      if(url){
        const page = url.split("page=")[1];
        this.loading = true;
        await this.store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default", dificulty: this.filter.dificulty, status: this.filter.status, search: this.filter.search, page: page });
        this.loading = false;
      }
     
    },
   async doSearch() {

      // push page to the url - check if already have a search query and concatenate the page number

      if(this.filter.search || this.filter.dificulty || this.filter.status){
          window.history.pushState({}, "", "/laboratory/competition-machines?page=1&search=" + this.filter.search + "&dificulty=" + this.filter.dificulty + "&status=" + this.filter.status);
      }

      if(this.loading){
        return;
      }
     
      this.loading = true;
     
      await this.store.dispatch(Actions.GET_MACHINES_FILTERED, { type: "default", dificulty: this.filter.dificulty, status: this.filter.status, search: this.filter.search});
      this.loading = false;
      

    }
  }
});
