
import { computed, defineComponent } from "vue";
import { Actions} from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import {hasFeature} from "@/core/helpers/feature";

export default defineComponent({
  name: "account-base",
  components: {
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    return {
      user,
      currentLanguage,
      store,
      Actions,
      hasFeature
    };
  },
});
