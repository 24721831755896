import {Actions} from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { computed } from "vue";

export function hasFeature(feature: string) {
    const store = useStore();
    console.log('[>] Checking user feature:', feature);

    const hasAccess = store.getters.currentUser.features?.includes(feature);
    console.log('[>] Has access:', hasAccess);
    return hasAccess;
}
