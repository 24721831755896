
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "oauth",
  props: {
    provider: {
      type: String,
      default: null,
    },
  },
  components: {},
  created: function () {
    this.startSSO(this.provider);
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const generateState = () => {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    };

    const GoogleAuth = () => {  
      // reset local storage for checkout redirect
      localStorage.removeItem('checkout_redirect');

      if(window.location.search.includes('checkout_redirect')){
        // set local storage for checkout redirect
        localStorage.setItem('checkout_redirect', '1');        
      }
      
        const sso_url =
        "https://accounts.google.com/o/oauth2/auth?response_type=code&scope=openid+profile+email&state=" +
        generateState() +
        "&client_id=" +
        process.env.VUE_APP_GOOGLE_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_GOOGLE_REDIRECT_URI;
        window.location.href = sso_url;
      
    };
    const FacebookAuth = () => {
      const sso_url =
        "https://www.facebook.com/v3.3/dialog/oauth?client_id=" +
        process.env.VUE_APP_FACEBOOK_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_FACEBOOK_REDIRECT_URI + "?checkout_redirect=1" + 
        "&response_type=code&scope=email&state=" +
        generateState();
      window.location.href = sso_url;
    };
    const GithubAuth = () => {

      // reset local storage for checkout redirect
      localStorage.removeItem('checkout_redirect');
      if(window.location.search.includes('checkout_redirect')){
        // set local storage for checkout redirect
        localStorage.setItem('checkout_redirect', '1');
      }

        const sso_url =
        "https://github.com/login/oauth/authorize?client_id=" +
        process.env.VUE_APP_GITHUB_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_GITHUB_REDIRECT_URI + 
        "&scope=user&allow_signup=false&state=" +
        generateState();
      window.location.href = sso_url;
      
    };

    const startSSO = (provider) => {
      switch (provider) {
        case "google":
          GoogleAuth();
          break;
        case "facebook":
          FacebookAuth();
          break;
        case "github":
          GithubAuth();
          break;
        default:
          console.log("No provider");
          break;
      }
    };
    return {
      startSSO,
      currentLanguage
    };
  },
});
