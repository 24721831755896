
import ApiService from "@/core/services/ApiService";
import {Actions} from "@/store/enums/StoreEnums";
import {defineComponent, computed, ref, onBeforeMount, onMounted} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import type {ScoreSystemType} from "@/types/ScoreSystemType";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "leaderboard-component",
  data() {
    return {
       vpn: {
        ip: "",
        online: false
        }
    }
    },
    components: {

    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { t } = useI18n();
      const id = ref(2);
      const scoreboard = computed(() => {
         return store.getters.getCurrentScoreSystem;
      });

      const user = computed(() => {
        return store.getters.currentUser;
      });
      const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

      onMounted( async () =>{
        try{
          await store.dispatch(Actions.GET_SCORE_SYSTEM, id.value);
        }catch (e) {
          console.log(e)
        }
      });

      const scoreLevel = (value: number) => {
        let level = Math.floor(value / 1000);
        let xp = value % 1000;

        return {
          level: level < 1 ? 0 : level,
          xp: xp
        };
      }
      const userPatent = (score: number) => {
      const patents = [
        { range: [0, 1999], name: 'recruit', icon: 'recruta.png' },
        { range: [2000, 3999], name: 'soldier', icon: 'soldado.png' },
        { range: [4000, 5999], name: 'cable', icon: 'cabo.png' },
        { range: [6000, 7999], name: '3rd_sergeant', icon: '3-sargento.png' },
        { range: [8000, 9999], name: '2rd_sergeant', icon: '2-sargento.png' },
        { range: [10000, 11999], name: '1rd_sergeant', icon: '1-sargento.png' },
        { range: [12000, 13999], name: 'warrant_officer', icon: 'subtenente.png' },
        { range: [14000, 15999], name: 'aspirant', icon: 'aspirante.png' },
        { range: [16000, 17999], name: '2nd_lieutenant', icon: '2-tenente.png' },
        { range: [18000, 19999], name: '1nd_lieutenant', icon: '1-tenente.png' },
        { range: [20000, 21999], name: 'captain', icon: 'capitao.png' },
        { range: [22000, 23999], name: 'major', icon: 'major.png' },
        { range: [24000, 25999], name: 'lieutenant_colonel', icon: 'tenente-coronel.png' },
        { range: [26000, 27999], name: 'colonel', icon: 'coronel.png' },
        { range: [28000, 29999], name: 'attack_general', icon: 'general-de-ataque.png' },
        { range: [30000, 31999], name: 'stealth_general', icon: 'general-de-furtividade.png' },
        { range: [32000, 33999], name: 'general_of_intelligence', icon: 'general-de-inteligencia.png' },
        { range: [34000, 39999], name: 'marshal', icon: 'marechal.png' },
        { range: [40000, 49999], name: 'supreme_commander', icon: 'supremo-comandante.png' },
        { range: [50000, 59999], name: 'grand_marshal', icon: 'grao-marechal.png' },
        { range: [60000, 69999], name: 'warlord', icon: 'senhor-da-guerra.png' },
        { range: [70000, 79999], name: 'arconte', icon: 'arconte.png' },
        { range: [80000, 89999], name: 'supreme_leader', icon: 'lider-supremo.png' },
        { range: [90000, 99999], name: 'primordial', icon: 'primordial.png' },
        { range: [100000, Infinity], name: 'hakai', icon: 'hakai.png' }
      ];

      const defaultPatent = {
        name: t(`${currentLanguage.value}.patents.recruit`),
        icon: 'recruta.png'
      };

      if (!score) return defaultPatent;

      const foundPatent = patents.find(
        ({ range }) => score >= range[0] && score <= range[1]
      );

      if (foundPatent) {
        return {
          name: t(`${currentLanguage.value}.patents.${foundPatent.name}`),
          icon: foundPatent.icon
        };
      }

      return defaultPatent;
    };

      return {
        user,
        scoreboard,
        scoreLevel,
        currentLanguage,
        userPatent

      };
    },
});
