
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import router from "@/router";
import type { CertificateDataType } from "@/types/CertificateType";
import ApiService from "@/core/services/ApiService";
import { UserType, Certificate } from "@/types/UserType";
import { Actions } from "@/store/enums/StoreEnums";
import { MachineType } from "@/types/MachineType";
import {hasFeature} from "@/core/helpers/feature";

export default defineComponent({
  name: "course-catalog",
  components: {
  },
  setup() {
    const store = useStore();
    const user = computed<UserType>(() => store.getters.currentUser);
    const lastMachine = ref({} as MachineType);
    store.dispatch("getAllCourses");
    const courses = computed(() => store.getters.getCourseData);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    const certificatelength = ref(0);
    const calcProgress = ref(0);
    const certificatesResponse = ref({
      status: 0,
      data: []
    });
    const certificates = ref<CertificateDataType[]>([]);
    //const certificatelength = ref(0);
    const loading = ref(false);
    const { t } = useI18n();
    
    
    const userPublic = computed<UserType>(() => store.getters.publicUser);

    onMounted(async () => {
      loading.value = true
      // /api/lab/last
      ApiService.get('/labs/last').then((response) => {
        lastMachine.value = response.data.data;
      }).catch((error) => {
        lastMachine.value = {} as MachineType;
      });
      // certificatelength
      console.log(user.value.id)
      await store.dispatch(Actions.GET_PUBLIC_USER, { id: user.value?.id || 'me' });
      
      
      const calcProgress = computed(() => {
        return (userPublic.value.score['value'])
      });

      const certificatelength = computed(() => {
        //console.log(userPublic.value.certificates.length)
        return userPublic.value?.certificates?.length || 0;
      });

      loading.value = false
      
    })

    const userPatent = computed(() => {
      const patent = {
        name: '',
        icon: ''
      };

      if (store.getters.publicUser?.score) {
        const current_value = store.getters.publicUser.score.value;

        const patents = [
          { range: [0, 1999], name: 'recruit', icon: 'recruta.png' },
          { range: [2000, 3999], name: 'soldier', icon: 'soldado.png' },
          { range: [4000, 5999], name: 'cable', icon: 'cabo.png' },
          { range: [6000, 7999], name: '3rd_sergeant', icon: '3-sargento.png' },
          { range: [8000, 9999], name: '2rd_sergeant', icon: '2-sargento.png' },
          { range: [10000, 11999], name: '1rd_sergeant', icon: '1-sargento.png' },
          { range: [12000, 13999], name: 'warrant_officer', icon: 'subtenente.png' },
          { range: [14000, 15999], name: 'aspirant', icon: 'aspirante.png' },
          { range: [16000, 17999], name: '2nd_lieutenant', icon: '2-tenente.png' },
          { range: [18000, 19999], name: '1nd_lieutenant', icon: '1-tenente.png' },
          { range: [20000, 21999], name: 'captain', icon: 'capitao.png' },
          { range: [22000, 23999], name: 'major', icon: 'major.png' },
          { range: [24000, 25999], name: 'lieutenant_colonel', icon: 'tenente-coronel.png' },
          { range: [26000, 27999], name: 'colonel', icon: 'coronel.png' },
          { range: [28000, 29999], name: 'attack_general', icon: 'general-de-ataque.png' },
          { range: [30000, 31999], name: 'stealth_general', icon: 'general-de-furtividade.png' },
          { range: [32000, 33999], name: 'general_of_intelligence', icon: 'general-de-inteligencia.png' },
          { range: [34000, 39999], name: 'marshal', icon: 'marechal.png' },
          { range: [40000, 49999], name: 'supreme_commander', icon: 'supremo-comandante.png' },
          { range: [50000, 59999], name: 'grand_marshal', icon: 'grao-marechal.png' },
          { range: [60000, 69999], name: 'warlord', icon: 'senhor-da-guerra.png' },
          { range: [70000, 79999], name: 'arconte', icon: 'arconte.png' },
          { range: [80000, 89999], name: 'supreme_leader', icon: 'lider-supremo.png' },
          { range: [90000, 99999], name: 'primordial', icon: 'primordial.png' },
          { range: [100000, Infinity], name: 'hakai', icon: 'hakai.png' }
        ];

        const foundPatent = patents.find(
          ({ range }) => current_value >= range[0] && current_value <= range[1]
        );

        if (foundPatent) {
          patent.name = t(`${currentLanguage.value}.patents.${foundPatent.name}`);
          patent.icon = foundPatent.icon;
        } else {
          patent.name = t(`${currentLanguage.value}.patents.recruit`);
          patent.icon = 'recruta.png';
        }
      }

      return patent;
    });


    const scoreLevel = (value: number | null) => {
      if (typeof value !== 'number' || !value) return { level: 0, xp: 0 };
      let level = Math.floor(value / 1000);
      let xp = value % 1000;

      return {
        level: level < 1 ? 0 : level,
        xp: xp
      };
    }

    return {
      user,
      courses,
      router,
      currentLanguage,
      certificatelength,
      loading,
      userPatent,
      userPublic,
      calcProgress,
      scoreLevel,
      lastMachine,
      hasFeature
    };
  },
  methods: {
    openCourse(id) {
      this.$router.push({
        name: "training-course",
        params: {
          course_id: id
        }
      });
    }
  }
});
